import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";

const MisionCompromiso = () => {
  return (
    <>
      <SEO title="Recursos Humanos" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Recursos Humanos"
          paragraph="<p>El mayor capital de una empresa es su gente, así entendemos en Fradealco S.A., alentando el desarrollo del personal y su crecimiento dentro de la organización.
                <p>Se estimulan los aportes que se traducen en mejoras concretas así como el espíritu de colaboración y el trabajo en equipo.</p>
                <p>Las grandes empresas crecen gracias al talento y al esfuerzo de los hombres y mujeres que el día a día trabajan con empeño y dedicación.</p> "
        />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default MisionCompromiso;
