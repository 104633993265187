import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const ControlesInscripcion = () => {
    return (
        <>
            <SEO title="Controles e inscripciones" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Controles e inscripciones"
                paragraph='
                <p>
                    <div><strong>Controles:</strong></div>
                    Cromatográfico Gaseoso - Espectrofotométrico - Físico - Químico
                </p>
                <p>  
                    <div><strong>Inscripciones:</strong></div>
                    <ul>
                        <li>Ministerio de Salud Pública de la Nación - A.N.M.A.T. Cert N° 2323/96 / Cambio de DT DISP 1611/05.</li>
                        <li>Ministerio de Salud de la Pcia. de Bs. As. - Disposición N° 1635/95.</li>
                        <li>Instituto Nacional de Vitivinicultura I.N.V XAD 104 - XAH 329 - XAF 201 - XAA 001.</li>
                        <li>Registro Nacional de Precursores Químicos RNPQ N° 05905/02.</li>
                        <li>RNE 02-033.829.</li>
                        <li>RNE 020035181.</li>
                        <li>N° Legajo elaborador de productos cosmeticos N° 2797</li>
                        <li>Sistema de Gestión de Calidad certificado bajo la Norma ISO 9001:2015.</li>
                        <li>Sistema de Gestión de Medio Ambiente certificado bajo la Norma ISO 14001:2015.</li>
                    </ul>
                </p>'
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ControlesInscripcion;