import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const BannerFour = () => {
  return (
    <>
      <div className="banner banner-style-4">
        <div className="container">
          <Carousel interval={2000} variant="dark">
            <Carousel.Item>
              <div className="banner-content">
                <h1 className="title">Alcohol etílico 96 °</h1>
                {/* <p>Uso Medicinal y Alimenticio</p> */}
                <div>
                  <Link
                    to={process.env.PUBLIC_URL + "/producto/2/alcohol-etlico-96/"}
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Ver producto
                  </Link>
                </div>
              </div>
              <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img alt="" src={process.env.PUBLIC_URL + "/images/alcoholHome.png"} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <h1 className="title">Gel de Alcohol etílico</h1>
                {/* <p>Gel antibacterial sin acción terapéutica, incoloro y sin perfume a base de Alcohol etílico.</p> */}
                <div>
                  <Link
                    to={process.env.PUBLIC_URL + "/producto/11/gel-de-alcohol-etlico/"}
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Ver producto
                  </Link>
                </div>
              </div>
              <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img alt="" src={process.env.PUBLIC_URL + "/images/homeGel.png"} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <h1 className="title">Vinagre de mesa</h1>
                {/* <p>Vinagre de Alcohol, Vino y Manzana</p> */}
                <div>
                  <Link
                    to={process.env.PUBLIC_URL + "/producto/15/vinagre-de-mesa/"}
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Ver producto
                  </Link>
                </div>
              </div>
              <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img alt="" src={process.env.PUBLIC_URL + "/images/homeVinagre.png"} />
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="banner-content">
                <h1 className="title">Alcohol desnaturalizado</h1>
                <p> </p>
                <div>
                  <Link
                    to={process.env.PUBLIC_URL + "/producto/4/alcohol-desnaturalizado/"}
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    Ver producto
                  </Link>
                </div>
              </div>
              <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img alt="" src={process.env.PUBLIC_URL + "/images/homeQuemar.png"} />
                </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>
                        <div className="banner-content">
                            <h1 className="title">Certificación BPM</h1>
                            <p>Ser Líder nos exige compromiso de superación permamente.</p>
                            <div>
                                <Link to={process.env.PUBLIC_URL + "/buenas-practicas-manofactura"} className="axil-btn btn-fill-primary btn-large">Ver certificado BPM</Link>
                            </div>
                        </div>
                        <div className="banner-thumbnail">
                            <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="banner-content">
                            <h1 className="title">Certificación BPM</h1>
                            <p>Ser Líder nos exige compromiso de superación permamente.</p>
                            <div>
                                <Link to={process.env.PUBLIC_URL + "/buenas-practicas-manofactura"} className="axil-btn btn-fill-primary btn-large">Ver certificado BPM</Link>
                            </div>
                        </div>
                        <div className="banner-thumbnail">
                            <div className="large-thumb">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" />
                            </div>
                        </div>
                    </Carousel.Item> */}
          </Carousel>
        </div>

        <ul className="list-unstyled shape-group-19">
          <li className="shape shape-1">
            <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
          </li>
          <li className="shape shape-2 mobileNo">
            <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default BannerFour;
