import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import FormTwo from "../component/contact/FormTwo";

const Contact = () => {
  return (
    <>
      <SEO title="Contáctanos" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />

        <div className="section section-padding paddingTop">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Dejanos tu consulta</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--50 mb_md--30 mt_md--0 mt--20">
                  <h4 className="title">Teléfono</h4>
                  <p className="sM">Nuestro horario de atención es de Lunes a Viernes de 9 a 18 hs</p>
                  <h4 className="phone-number">
                    <a href="tel:541177001605">+54 11 7700-1605</a>
                  </h4>
                  <br />
                  <br />
                  <h4 className="title">Email</h4>
                  <p className="sM">Atención al cliente</p>
                  <h3 className="email">
                    <a href="mailto:fradealco@fradealco.com.ar">fradealco@fradealco.com.ar</a>
                  </h3>

                  <p className="sM">Ventas Retail</p>
                  <h4 className="email">
                    <a href="mailto:ventas3@fradealco.com.ar">ventas3@fradealco.com.ar</a>
                  </h4>

                  <p className="sM">Ventas Industria</p>
                  <h4 className="email">
                    <a href="mailto:graneles@fradealco.com.ar">graneles@fradealco.com.ar</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
