import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter,  FaLinkedin, FaInstagram, FaEnvelopeOpen } from "react-icons/fa";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ContactLocation from "../../component/contact/ContactLocation";
  
const FooterOne = ({parentClass}) => {
  const form = useRef();

  const [formData, setFormData] = useState({
    email: '',
  });
  const [catalogoSent, setCatalogoSent] = useState(false);
  const [loadingCatalog, setLoadingCatalog] = useState(false);


  const handleSubmit =  async (e) => {
    e.preventDefault();
    if(loadingCatalog) return;

    setLoadingCatalog(true)

    const urlApi = 'https://api-email.fradealco.com.ar/';
    const { email } = formData;

    const formContact = new FormData();
    formContact.append('type', 'contact');
    formContact.append('subject', 'Nueva solicitud de catálogo');
    formContact.append('body', `
      El siguiente correo solícito el catálogo ${new Date().getFullYear()} de Fradealco. <br /><br />
      <strong>Email:</strong> ${email}<br />
    `);

    fetch(urlApi, {
      method: 'POST',
      body: formContact
    }).then(async (responseContact) => {
      console.log(responseContact)
    })
    .catch((error) => { console.log(error) });


    const formCatalogo = new FormData();
    formCatalogo.append('type', 'catalogo');
    formCatalogo.append('to_email', email);
    fetch(urlApi, {
      method: 'POST',
      body: formCatalogo
    }).then((response) => { 
      console.log(response)
      setLoadingCatalog(false)
      setCatalogoSent(true);
    })
    .catch((error) => { console.log(error) });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };


  return (<>
      <div className="marginTopO section bg-color-dark overflow-hidden">
      <div className="container">
        <SectionTitle title="Nuestras plantas" description="" textAlignment="heading-light-left" textColor="" />
        <div className="row">
          <ContactLocation />
        </div>
      </div>
      <ul className="shape-group-11 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" />
        </li>
      </ul>
    </div>

      <footer className={`footer-area ${parentClass}`}>
          <div className="container">
              <div className="footer-main">
                  <div className="row">
                      <div className="col-xl-6 col-lg-5">
                          <div className="footer-widget border-end">
                              <div className="footer-newsletter">
                                  <h4 className="title">Catálogo {new Date().getFullYear()}</h4>

                                  {catalogoSent ? 
                                    <p className="text-success">El catálogo fue enviado a tu e-mail</p> 
                                    : 
                                    <>
                                      <p>Recibí el catálogo en tu e-mail</p>
                                      <form ref={form} onSubmit={handleSubmit} >
                                          <div className="input-group">
                                              <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                              <input value={formData.email} name="email" onChange={handleChange} type="email" className="form-control backWhte" placeholder="Dirección de email" required />
                                              <button className="subscribe-btn" type="submit">{loadingCatalog ? 'Cargando...' : 'Enviar catálogo' }</button>
                                          </div>
                                      </form>
                                    </>
                                  }
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-6 col-lg-7">
                          <div className="row">
                              <div className="col-sm-6">
                                  <div className="footer-widget">
                                      <h6 className="widget-title">Enlaces Utiles</h6>
                                      <div className="footer-menu-link">
                                          <ul className="list-unstyled">
                                              <li><Link to={process.env.PUBLIC_URL + "/productos"}>Productos</Link></li>
                                              <li> <Link to={process.env.PUBLIC_URL + "/nuestras-politicas"}>Nuestras Políticas</Link></li>
                                              <li><Link to={process.env.PUBLIC_URL + "/recursos-humanos"}>Recursos Humanos</Link></li>
                                              <li><Link to={process.env.PUBLIC_URL + "/contacto"}>Contacto</Link></li>

                                              
                                          </ul>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-sm-6">
                                  <div className="footer-widget">
                                      <div className="footer-menu-link">
                                          <div className="footer-top">
                                              <div className="footer-social-link">
                                                  <ul className="list-unstyled">
                                                      <li><a target="_blank" href="https://www.facebook.com/fradealco" rel="noreferrer"><FaFacebookF style={{color:'#146be3'}} /></a></li>
                                                      <li><a target="_blank" href="https://www.twitter.com/fradealco" rel="noreferrer"><FaTwitter style={{color:'#2b9bf0'}}  /></a></li>
                                                      <li><a target="_blank" href="https://www.linkedin.com/company/fradealco" rel="noreferrer"><FaLinkedin style={{color:'#1666c2'}}  /></a></li>
                                                      <li><a target="_blank" href="https://www.instagram.com/fradealco.sa/" rel="noreferrer"><FaInstagram style={{color:'#c80670'}}  /></a></li>
                                                  </ul><br />

                                                  <strong>Teléfono:</strong> <a href="tel:541177001605">+54 11 7700-1605</a><br />
                                                  <br />
                                                  <strong>Atención al cliente:</strong> <a href="mailto:fradealco@fradealco.com.ar">fradealco@fradealco.com.ar</a><br />
                                                  <strong>Ventas Retail:</strong> <a href="mailto:ventas3@fradealco.com.ar">ventas3@fradealco.com.ar</a><br />
                                                  <strong>Ventas Industria:</strong> <a href="mailto:graneles@fradealco.com.ar">graneles@fradealco.com.ar</a><br />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="footer-bottom"> 
                  <div className="row">
                      <div className="col-md-12">
                          <div className="footer-copyright">
                              <span className="copyright-text">© {new Date().getFullYear()} - Ser lider nos exige compromiso de superación permanente.</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
      </>
  )
}

export default FooterOne;