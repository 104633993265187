import React from 'react';
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import { Link } from 'react-router-dom';


const allData = TestimonialData;
const TestimonialItem = ({colSize, itemShow}) => {
    return (
        <>
            {allData.slice(0, itemShow).map((data, index) => (
                <div className={`${colSize}`} key={index}>
                    <h4>{data.fromtext}</h4>
                    <div className="testimonial-grid">
                        <span className="social-media">
                            <img class="imgCertificados" src={process.env.PUBLIC_URL + data.from} alt={data.fromtext} />
                        </span>
                        <p>{data.description}</p>
                        <div className="content">
                            <Link to={process.env.PUBLIC_URL +data.link} className="axil-btn btn-fill-primary btn-small">{data.labelLink}</Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default TestimonialItem;