import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const NuestrasPoliticas = () => {
    return (
        <>
            <SEO title="Política de Calidad y Medio Ambiente" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Política Ambiental y de Calidad"
                paragraph='
                <p>El compromiso de <strong>FRADEALCO S.A.</strong> se basa en:</p>
                <ul style="margin-top:50px">
                    <li style="margin-bottom:20px">Llevar a cabo nuestro trabajo dentro de un entorno de gestión que garantice una <strong>mejora continua en nuestros procesos</strong>, mediante una revisión periódica de nuestros objetivos establecidos del Sistema de Gestión.</li>
                    <li style="margin-bottom:20px">Producir productos que cumplan los <strong>estándares de calidad e inocuidad</strong>, ofreciendo productos inocuos a los clientes a través de la implementación y mejora continua del programa de Análisis de Peligros y Puntos críticos de control (HACCP).</li>
                    <li style="margin-bottom:20px"><strong>Proteger el medio ambiente</strong> y nuestro entorno estableciendo medidas orientadas a la prevención de cualquier tipo de contaminación que pudiera originarse por operaciones debidas a nuestra actividad.</li>
                    <li style="margin-bottom:20px">Promover una comprensión y <strong>difusión de nuestra política de calidad e inocuidad y gestión ambiental</strong> dentro de nuestra EMPRESA y aquellos que trabajen en nombre de ella.</li>
                    <li style="margin-bottom:10px">Controlar de manera efectiva todos nuestros procesos teniendo énfasis en:</li>
                    <ol style="margin-bottom:20px">
                        <li>Calidad y seguridad de nuestros productos.</li>
                        <li>Eficiencia y eficacia de nuestros servicios/productos.</li>
                        <li>Relaciones con nuestros clientes.</li>
                        <li>Cuidado del Medioambiente.</li>
                    </ol>
                    <li><strong>Cumplir con todos los requisitos</strong>, ya sean estos <strong>legales</strong> u otros que nos sean aplicables en razón de nuestra actividad.</li>
                </ul>'
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                {/* <div style={{textAlign:'center'}}>
                    <Link to="/buenas-practicas-manofactura">
                        <Tilty perspective={4000} reset={false}>
                            <img src={process.env.PUBLIC_URL + '/images/others/bpmNewClaypole.png'} alt="Buenas Prácticas de Manufactura" />
                        </Tilty>
                    </Link>
                </div> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default NuestrasPoliticas;
