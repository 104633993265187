import React from "react";
import styled from "styled-components";
import backgroundVideo from "../assets/video/background.mp4";
import backgroundVideoW from "../assets/video/background.webm";

const Section = styled.section`
  width: 100vw;
  height: 100vh;
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: var(--dark);
  overflow: hidden;


`;

const VideoContainer = styled.div`
  width: 100vw;
  min-height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }
`;
const HeroSection = () => {


  return (
    <Section>
      <VideoContainer>
        <div className="fullscreen-video-wrap" dangerouslySetInnerHTML={{ __html: `
          <video
          autoplay="autoplay"
          loop="loop"
          muted
          defaultMuted
          playsinline
          oncontextmenu="return false;"
          preload="auto">
            <source src=${backgroundVideo} type="video/mp4" />
            <source src=${backgroundVideoW} type="video/webm" />
          </video>     
        `}}></div>
      </VideoContainer>
    </Section>
  );
};

export default HeroSection;
