import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Fradealco | {title}</title>
            <meta name="keywords" content="Alcoholes,jugo de limon,graneles,industrial,mf,purocol,comensal,mas fuego, azufre en barra,etanol, alcohol de quemar, vinagre de mesadescription" />
            <meta name="author" content="Rodrigo Zaupa" />
            <meta name="description" content="" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            
            <script strategy="lazyOnload" 
            dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-01VWSB2L37', {
              page_path: window.location.pathname,
            });`}} jsx global />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;