import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import Tilty from "react-tilty";
import { Link } from "react-router-dom";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const Bpm = () => {
  return (
    <>
      <SEO title="Buenas Prácticas de Manufactura" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Buenas Prácticas de Manufactura"
          paragraph="
                <p>Los consumidores son cada vez más exigentes buscando calidad de los productos que adquieren. La inocuidad de los alimentos es una característica esencial.</p>
                <p>Las Buenas Prácticas de Manufactura “BPM” son un conjunto de principios con el objetivo de garantizar que los productos se fabriquen en condiciones sanitarias adecuadas y se disminuyan los riesgos inherentes a la producción y distribución.</p>
                <p>Estas normas definen las condiciones que deben estar presentes en toda planta de procesamiento de alimentos, por lo que la capacitación del personal es fundamental para la implementación y mantenimiento de sistemas de gestión de inocuidad, las BPM se centralizan en la higiene y manera de manipulación de materias primas, envases, productos terminados, condiciones de equipamiento, controles de calidad, etc.</p>
                <p>La Norma IRAM 324:2010 es una norma internacional certificable que permite contar un Sistema de Gestión que apunta a elaborar productos seguros e inocuos para los consumidores.</p>"
          styleClass="thumbnail-4"
          // mainThumb=""
        />
        <div style={{ textAlign: "center", margin: "60px" }}>
          <Tilty perspective={4000} reset={false}>
            <img
              src={process.env.PUBLIC_URL + "/images/others/bpmNewClaypole.png"}
            />
          </Tilty>
        </div>

        <div style={{ textAlign: "center", margin: "60px" }}>
          <Link
            style={{ marginRight: "30px" }}
            to={`${process.env.PUBLIC_URL}/nuestras-politicas`}
            className="axil-btn btn-fill-primary"
          >
            Nuestras Políticas
          </Link>
          <a
            href="https://www.iram.org.ar"
            target="_blank"
            className="axil-btn btn-fill-primary"
          >
            IRAM
          </a>
        </div>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Bpm;
