/* eslint-disable react-hooks/exhaustive-deps */
import gsap from "gsap";
import React from "react";
import { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useContext } from "react";
import { ColorContext } from "./../context/ColorContext";
import { useEffect } from "react";

const Section = styled.section`
  width: 100vw !important;
  height: 100vh !important;
  position: relative;
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  background-color: rgba(155, 181, 206, 0.8);
  position: relative;

  @media screen and (max-width: 48em) {
    width: 100%;
  }
  img {
    margin: 30px auto 0px auto;
    object-fit: "contain";
    @media screen and (max-width: 48em) {
      margin: 250px auto 0px auto;
      height: fit-content;
      width: fit-content;
      max-height: 70%;
    }
  }
`;
const Right = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  background-color: rgba(155, 181, 206, 0.4);
  position: relative;

  @media screen and (max-width: 48em) {
    display: none;
  }
  img {
    margin: 30px auto 0px auto;
    object-fit: "contain";
  }
`;

const Center = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  font-size: var(--fontxxl);
  text-transform: uppercase;
  filter: brightness(0.85);
  font-weight: 800;
  @media screen and (max-width: 48em) {
    top: 2rem;
    font-size: var(--fontxl);
    line-height: 40px;
    transform: translate(-50%, 0%) rotate(0deg);
  }
`;

const ColorSection = () => {
  const sectionRef = useRef(null);
  const rightRef = useRef(null);
  const leftRef = useRef(null);
  const textRef = useRef(null);

  const { currentColor, changeColorContext } = useContext(ColorContext);

  useEffect(() => {
    let rightElem = rightRef.current;
    let leftElem = leftRef.current;
    let textElem = textRef.current;

    textElem.innerText = currentColor.text;
    textElem.style.color = currentColor.color;

    rightElem.style.backgroundColor = `rgba(${currentColor.rgbColor}, 0.4)`;
    leftElem.style.backgroundColor = `rgba(${currentColor.rgbColor}, 0.8)`;
  }, [currentColor]);

  useLayoutEffect(() => {
    let Elem = sectionRef.current;

    let updateColor = (color, text, rgbColor, image) => {
      const colorObj = {
        color,
        text,
        rgbColor,
        image,
      };
      changeColorContext(colorObj);
    };

    // pin the section
    gsap.to(Elem, {
      scrollTrigger: {
        trigger: Elem,
        start: "top top",
        end: `+=${Elem.offsetWidth}`,
        scrub: 1,
        pin: true,
        pinSpacing: true,
      },
    });
    let t2 = gsap
      .timeline({
        scrollTrigger: {
          trigger: Elem,
          start: "top top",
          end: `+=${Elem.offsetWidth}`,
          scrub: 1,
        },
      })
      .to(Elem, {
        onStart: updateColor,
        onStartParams: ["#9BB5CE", "Alcohol etílico 96°", "155, 181, 206", "alcohol_mf"],
        onReverseComplete: updateColor,
        onReverseCompleteParams: ["#9BB5CE", "Alcohol etílico 96°", "155, 181, 206", "alcohol_mf"],
      })
      .to(Elem, {
        onStart: updateColor,
        onStartParams: ["#F9E5C9", "Alcohol en gel Purocol", "249, 229, 201", "alcohol_gel"],
        onReverseComplete: updateColor,
        onReverseCompleteParams: ["#9BB5CE", "Alcohol etílico 96°", "155, 181, 206", "alcohol_mf"],
      })
      .to(Elem, {
        onStart: updateColor,
        onStartParams: ["#505F4E", "Alcohol etílico Purocol", "80, 95, 78", "alcohol_purocol"],
        onReverseComplete: updateColor,
        onReverseCompleteParams: ["#F9E5C9", "Alcohol en gel Purocol", "249, 229, 201", "alcohol_gel"],
      });

    return () => {
      if (t2) t2.kill();
    };
  }, []);

  return (
    <>
      <Section ref={sectionRef}>
        <Left ref={leftRef} style={{ textAlign: "center" }}>
          <img src={`${process.env.PUBLIC_URL}/images/homeProducts/${currentColor.image}_first.png`} alt="" />
        </Left>
        <Center ref={textRef} />
        <Right ref={rightRef}>
          <img src={`${process.env.PUBLIC_URL}/images/homeProducts/${currentColor.image}_second.png`} alt="" />
        </Right>
      </Section>
    </>
  );
};

export default ColorSection;
