import React, {useRef, useState} from 'react';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Tu comentario fue enviado correctamente.
    </Alert>
  )
}

const FormTwo = () => {
  const form = useRef();
  const [ result, showresult ] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const handleSubmit =  async (e) => {
    e.preventDefault();

    const { name, email, phone, message } = formData;

    const formContact = new FormData();
    formContact.append('type', 'contact');
    formContact.append('body', `
      Nuevo contacto desde la web (fradealco.com.ar): <br /><br />
      <strong>Nombre:</strong> ${name}<br />
      <strong>Email:</strong> ${email}<br />
      <strong>Teléfono:</strong> ${phone}<br />
      <strong>Mensaje:</strong> ${message}
    `);

    await fetch('https://api-email.fradealco.com.ar/', {
      method: 'POST',
      body: formContact
    }).then((response) => {
      console.log(response)
      showresult(true);


      console.log('Email sent successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });

      setTimeout(() => {
        showresult(false);
      }, 5000);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="axil-contact-form">
      <div className="form-group">
          <label>Nombre</label>
          <input type="text" className="form-control" value={formData.name} onChange={handleChange} name="name" required />
      </div>
      <div className="form-group">
          <label>Email</label>
          <input type="email" className="form-control" value={formData.email} onChange={handleChange} name="email" required />
      </div>
      <div className="form-group">
          <label>Teléfono</label>
          <input type="tel" className="form-control" value={formData.phone} onChange={handleChange} name="phone" />
      </div>
      <div className="form-group mb--40">
          <label>Como podemos ayudarte?</label>
          <textarea className="form-control" name="message" onChange={handleChange} value={formData.message} rows="4" />
      </div>
      <div className="form-group">
          <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Enviar</button>
      </div>
      <div className="form-group">
          {result ? <Result /> : null}
      </div>
    </form>
  )
}

export default FormTwo;