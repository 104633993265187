import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { slugify } from "../../../utils";
import SimpleImageSlider from "react-simple-image-slider";

const PropOne = ({ projectStyle, portfolio, page }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          {portfolio.slider.length > 0 ? (
            <SimpleImageSlider
              width="100%"
              id={`asd_${page}`}
              height={410}
              bgColor="Transparent"
              navStyle={2}
              images={portfolio.slider}
              showNavs={true}
              autoPlay={false}
              onClick={() => {
                navigate(process.env.PUBLIC_URL + `/producto/${portfolio.id}/${slugify(portfolio.title)}/${page}`);
              }}
            />
          ) : (
            <Link to={process.env.PUBLIC_URL + `/producto/${portfolio.id}/${slugify(portfolio.title)}/${page}`}>
              <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
            </Link>
          )}
        </div>
        <div className="content">
          <h4 className="title">
            <Link to={process.env.PUBLIC_URL + `/producto/${portfolio.id}/${slugify(portfolio.title)}/${page}`}>
              {portfolio.title}
            </Link>
          </h4>
          <span className="subtitle">
            {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))}
          </span>
        </div>
      </div>
    </>
  );
};

export default PropOne;
