import React, {useRef, useState} from 'react';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Tu CV fue enviado correctamente.
        </Alert>
    )
}



const FormThree = () => {

  const form = useRef();
  const [ result, showresult ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    linkedin: '',
    cv: null
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Obtén el archivo seleccionado
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      cv: file  // Actualiza el estado con el archivo seleccionado
    }));
  };

        setTimeout(() => {
            showresult(false);
        }, 5000);

        const handleSubmit =  async (e) => {
          e.preventDefault();
          setLoading(true)
        
          const { name, email, phone, linkedin, cv } = formData;
        
          const formContact = new FormData();
          formContact.append('type', 'cv');
          formContact.append('body', `
            Nuevo CV recibido (fradealco.com.ar): <br /><br />
            <strong>Nombre:</strong> ${name}<br />
            <strong>Email:</strong> ${email}<br />
            <strong>Teléfono:</strong> ${phone}<br />
            <strong>Linkedin:</strong> ${linkedin}
          `);
          formContact.append('file', cv);

          await fetch('https://api-email.fradealco.com.ar/', {
            method: 'POST',
            body: formContact
          }).then((response) => {
            console.log(response)
            showresult(true);
        
        
            setFormData({
              name: '',
              email: '',
              phone: '',
              linkedin: '',
              cv: null
            });
            setLoading(false)
            setTimeout(() => {
              showresult(false);
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
          });
        };
        const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value
          });
        };
      
    return (
        <form ref={form} encType="multipart/form-data" onSubmit={handleSubmit} className="axil-contact-form">
        <div className="form-group">
            <label>Nombre completo</label>
            <input type="text" className="form-control" value={formData.name} onChange={handleChange}  name="name" required />
        </div>
        <div className="form-group row">
          <div  className="col-lg-6" >
            <label>E-mail</label>
            <input type="email" className="form-control" value={formData.email} onChange={handleChange} name="email" required />
          </div>
          <div  className="col-lg-6" >
            <label>Teléfono</label>
            <input type="tel" className="form-control" value={formData.phone} onChange={handleChange} name="phone" required />
          </div>
        </div>
        <div className="form-group row">
          <div  className="col-lg-6" >
              <label>URL Linkedin <small style={{textTransform: 'uppercase', fontSize: 10}}>(opcional)</small></label>
              <input type="text" value={formData.linkedin} onChange={handleChange} className="form-control" name="linkedin"  />
          </div>
          <div  className="col-lg-6" >
              <label>CV</label>
              <input type="file"  onChange={handleFileChange} className="form-control" name="cv" required />
          </div>
        </div>
        <div className="form-group text-end">
            <button type="submit" className="axil-btn btn-fill-primary w-auto btn-primary" name="submit-btn">{loading ? 'Enviando...' : 'Enviar'}</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormThree;