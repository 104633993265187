import React from 'react';
import Tilty from 'react-tilty';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const NuestrasPoliticas = () => {
    return (
        <>
            <SEO title="Certificación ISO 9001 e ISO 14001" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Certificación ISO 9001 e ISO 14001"
                paragraph=''
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                <div style={{textAlign:'center'}}>
                  <Tilty style={{display:'inline-block', marginRight:'50px'}} perspective={4000} reset={false}>
                    <img width="300px" src={process.env.PUBLIC_URL + '/images/9001.jpg'} />
                  </Tilty>

                  <Tilty  style={{display:'inline-block'}} perspective={4000} reset={false}>
                    <img  width="300px" src={process.env.PUBLIC_URL + '/images/140001.jpg'} />
                  </Tilty>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default NuestrasPoliticas;