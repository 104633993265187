import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import AboutFour from '../component/about/AboutFour';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const LogisticaTransporte = () => {

    return (
        <>
        <SEO title="Logística y Transporte" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Logística y Transporte"
            paragraph="
            <p>Una de las fortalezas de Fradealco S.A, ha sido su vasto y afianzado sistema de distribución que cubre todo el territorio Argentino y se proyecta paulatinamente hacia la integración con el Mercosur.</p>
            <p>La logística y distribución se realiza a través de nuestra propia flota de modernos camiones. Asegurando así la entrega de nuestros productos en óptimas condiciones en el momento que el cliente lo crea oportuno.</p>
            <p>Nuestro alto nivel de profesionalidad en esta area, nos permite desde hace más de 30 años complacer las más altas exigencias de nuestros clientes.</p>"
            styleClass="thumbnail-4"
            // mainThumb="/images/others/header-logistica.jpeg"
            />
            <AboutFour 
                title="Transporte"
                subtitle="Tecnología"
                mainThumb="/images/others/header-transporte-bg.jpeg"
                paragraph="<p>Contamos con una amplia flota de camiones Cisterna de acero inoxidable y aluminio, chasis y semis para garantizar tanto en tiempo y forma de entrega de nuestros productos, así como la no contaminación y estado óptimo de los mismos al llegar a nuestros clientes. Dicha flota nos permite abastecer a los más importantes supermercados, mayoristas, laboratorios e industrias de nuestro país.</p>"
            />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default LogisticaTransporte;