import React from 'react';
import { Link } from 'react-router-dom';


const FooterTwo = () => {

    return (
        <footer className="footer-area footer-dark">
        </footer>
    )
}

export default FooterTwo;