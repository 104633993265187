import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import AboutFour from '../component/about/AboutFour';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const MisionCompromiso = () => {

    return (
        <>
            <SEO title="Nuestra misión y compromiso" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Nuestra misión"
                paragraph="<p>Mejorar la calidad de vida del público consumidor satisfaciendo sus reales necesidades de la vida cotidiana con productos de la mejor calidad y al mejor precio.</p><img width='80%' style='margin:90px auto 0 auto!important;display:block' src='/images/others/header-team.jpeg' >"
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                <AboutFour 
                    title="Nuestro compromiso"
                    subtitle="Tecnología"
                    // mainThumb="/images/others/header-team.jpeg"
                    paragraph="
                    <div><strong>Con el consumidor:</strong></div>
                    <p>El consumidor es nuestra razón de ser, cada paso que damos debe estar encaminado a procurar su mayor satisfacción, seguridad y bienestar.</p>
                    
                    <div><strong>Con nuestros cientes:</strong></div>
                    <p>A quienes comercializan nuestros productos les proponemos el fortalecimiento continuo de nuestras relaciones comerciales y ponemos el mayor empeño en ofrecer el mejor servicio.</p>
                    
                    <div><strong>Con la ética:</strong></div>
                    <p>Debemos cumplir con nuestras obligaciones legales, morales, comerciales, respetar los compromisos asumidos y conservar una conducta recta en todos nuestros actos.</p>
                    "
                />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default MisionCompromiso;