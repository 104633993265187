import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const GarantiaCalidad = () => {
    return (
        <>
            <SEO title="Garantía de calidad" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Garantía de calidad"
                paragraph='
                <p>Fradealco S.A. concentra en sus manos el proceso completo de elaboración de cada producto con lo que se asegura el control de calidad de todas la etapas productivas.</p>
                <p>Fradealco S.A. cumple con todos los requisitos establecidos por los organismos de contralor, los cuales son obligatorios para todas las empresas que se dedican al fraccionamiento de Alcohol Etílico. Además contamos con un moderno Laboratorio de control de calidad a cargo de especializados profesionales bioquímicos y farmacéuticos con equipos de última generación para garantizar la estricta calidad superior que nos caracteriza, lo que nos permite obtener distintos tipos de alcoholes, aptos para diversos usos (Farmacopea, Licorera, Industria Alimenticia, Cosmética, Industria Quimica e Industria en general) Para ello el producto final debe superar estrictos controles de calidad antes de salir al mercado.</p>'
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default GarantiaCalidad;