import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';

const AboutFour = ({title, subtitle, mainThumb, paragraph, right}) => {
    return (
            <div className="section  case-study-featured-area">
                <div className="container">
                <div className="row">
                    {(mainThumb && !right) && (
                        <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + mainThumb} alt="" />
                            </div>
                        </div>
                    )}
                    <div className={mainThumb && "col-xl-5 col-lg-6"}>
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            {subtitle && (<span className="subtitle">{subtitle}</span>)}
                            {title && (<h2 className="title" dangerouslySetInnerHTML={{__html: title}}></h2>)}
                            {paragraph && (<p dangerouslySetInnerHTML={{__html: paragraph}}></p>)}
                            {/* <Link to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link> */}
                        </div>
                        {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="10" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="1500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    {(mainThumb && right) && (
                        <div className="col-xl-7 col-lg-6">
                            <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + mainThumb} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    )
}

export default AboutFour;