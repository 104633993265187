import React from "react";
import { Link, useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import ProjectData from "../data/products/data.json";
import VideoOne from "../component/video/VideoOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const allProjectData = ProjectData;

const ProjectDetails = () => {
  const params = useParams();
  const id = params.id;
  const back = params.back ? params.back : '';
  const detailsProject = allProjectData.find((data) => data.id === Number(id));
  const paragraph = detailsProject.category.map((cat, i) => cat);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  let presentacion = '';
  let imgBrand = '';
  if( detailsProject.imageBrand )
    imgBrand = `<div style="margin-bottom:30px"><img width="250px" src="${process.env.PUBLIC_URL}${detailsProject.imageBrand}"></div>`
  

    if(paragraph.length)
    presentacion = '<strong>Presentación:</strong><br />'
  return (
    <>
      <SEO title={detailsProject.title} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo
          back={back}
          title={`${imgBrand}${detailsProject.title}`}
          paragraph={`${presentacion}${paragraph.join(
            "<br />"
          )}`}
          mainThumb={detailsProject.image}
        />
        <section className="section-padding single-portfolio-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">{capitalizeFirstLetter(detailsProject.group)}</span>
                  <h5 className="title">{detailsProject.title}</h5>
                </div>
                {detailsProject.body.map((para, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                ))}
              </div>
              <div className="col-lg-6 offset-xl-1">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    <h4 className="title">
                      ¿Queres comprar nuestros productos?
                    </h4>
                    {/* <p>Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business.</p> */}
                    <Link
                      to={process.env.PUBLIC_URL + "/contacto"}
                      className="axil-btn btn-fill-primary"
                    >
                      Contactanos
                    </Link>
                    {detailsProject.video && <VideoOne url={detailsProject.video} />}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectDetails;
