import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import ProjectOne from "../component/project/ProjectOne";
import { useParams } from "react-router-dom";

const ProjectGridTwo = () => {
  const params = useParams();
  const { slug } = params;

  let title = "Nuestros Productos";

  if (slug === "medicinales") title = "Productos Medicinales";

  if (slug === "domisanitario") title = "Productos Domisanitario";

  if (slug === "perfumeria") title = "Productos Perfumería";

  if (slug === "alimenticios") title = "Productos Alimenticios";

  if (slug === "industrias") title = "Productos Industriales";

  return (
    <>
      <SEO title="Nuestros Productos" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <ProjectOne title={title} slug={slug} colSize="col-xl-4 col-md-6" columnGap="row-15" />
        <FooterOne />
      </main>
    </>
  );
};

export default ProjectGridTwo;
