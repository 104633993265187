import React, { useState, useEffect } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/products/data.json";
import { useNavigate } from "react-router-dom";

const filters = [
  {
    id: "all",
    label: "Todo",
  },
  {
    id: "medicinales",
    label: "Medicinales",
  },
  {
    id: "domisanitario",
    label: "Domisanitario",
  },
  {
    id: "perfumeria",
    label: "Perfumería",
  },
  {
    id: "alimenticios",
    label: "Alimenticios",
  },
  {
    id: "industrias",
    label: "Industriales",
  },
];

const AllData = ProjectData;
const ProjectOne = ({ parentClass, slug, title, colSize, itemShow, columnGap }) => {
  const navigate = useNavigate();

  const [getAllItems] = useState(AllData);
  const [page, setPage] = useState("");
  const [visiableProject, setVisiable] = useState(slug ? slug : "all");
  const [activeFilter, setActiveFilter] = useState("Todo");
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const filterSlug = filters.filter((s) => s.id === slug);
    const visiableProject_ = slug ? slug : "all";
    if (filterSlug.length > 0) setActiveFilter(filterSlug[0].label);
    else setActiveFilter(filters[0].label);

    setVisibleItems(getAllItems.filter((item) => item.group === visiableProject_ || visiableProject_ === "all"));
  }, [getAllItems, slug]);

  const handleChange = (e) => {
    e.preventDefault();
    let target = e.target.textContent;
    const getKey = filters.filter((s) => s.label === target);

    if (getKey[0].id === "all") {
      setPage(``);
      navigate(`/productos`);
    } else {
      setPage(`${getKey[0].id}`);
      navigate(`/productos/${getKey[0].id}`);
    }
    setVisiable(getKey[0].id);
    setActiveFilter(target);
    let tempData = [];
    if (target === filters[0].label) {
      tempData = getAllItems.filter((data) => data.group === visiableProject || visiableProject === "all");
    } else {
      for (let i = 0; i < getAllItems.length; i++) {
        const element = getAllItems[i];
        let categories = element["category"];

        if (categories.includes(target)) {
          tempData.push(element);
        }
      }
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <div style={{ paddingTop: "200px" }} className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
        <div className="container">
          <SectionTitle title={title} textAlignment="heading-left mb--40" textColor="" />
          <div className="isotope-button isotope-project-btn">
            {filters.map((filter) => (
              <button
                onClick={handleChange}
                className={filter.label === activeFilter ? "is-checked" : " "}
                key={filter.id}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <div className={`row ${columnGap ? columnGap : "row-35"}`}>
            {visibleItems.map((data) => (
              <div className={colSize ? colSize : "col-md-6"} key={data.id}>
                <ProjectPropOne projectStyle="" page={page} portfolio={data} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectOne;
