import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const NuestrasPoliticas = () => {
   return (
        <>
            <SEO title="Política de Responsabilidad Social Empresarial" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Política de Responsabilidad Social Empresarial"
                paragraph='
                <p>En FRADEALCO S.A. respetamos las buenas prácticas de empleo por convicción y apego a la Declaración Universal de los Derechos Humanos, el cumplimiento de las Garantías Individuales de la Constitución Nacional Argentina, la LCT (Ley de Contrato de Trabajo) y los derechos de los trabajadores expresados en leyes laborales y convenios colectivos de trabajo. Pagamos sueldos adecuados a la actividad de la empresa, y brindamos las condiciones de trabajo satisfactorias.</p>
                <br />
                <p>Valoramos la diversidad en nuestro personal y fomentamos la igualdad de oportunidades de contratación, empleo, promoción. Y promovemos que este valor se mantenga también en el trato entre trabajadores.</p>
                <br />
                
                <p>Todo el personal que ingrese a trabajar a FRADEALCO S.A. deberá estar registrado a partir del día de su contratación.</p>
                <br />
                
                <p style="margin-bottom:20px"><strong>Contratación de menores/Trabajo Forzoso:</strong> Velar por el cumplimiento de los derechos humanos y laborales establecidos en la Declaración Universal de los Derechos Humanos y en la legislación Argentina, rechazando rotundamente el trabajo infantil y forzoso, y respetando los derechos de asociación. En FRADEALCO S.A. no contratamos menores, repudiamos esa práctica, y establecemos pasos de control en reclutamiento y contrataciones.</p>
                
                <p style="margin-bottom:20px"><strong>Abuso laboral:</strong> En FRADEALCO S.A. rechazamos todo tipo de abuso, bullying o violencia de parte de los Directivos y Superiores y/o compañeros de trabajo.</p>
                
                <p style="margin-bottom:20px"><strong>Condiciones de trabajo:</strong> Para FRADEALCO S.A., la seguridad de los miembros de la empresa es fundamental para su adecuado funcionamiento. Por esta razón, poseemos un departamento que establece las prácticas tendientes a resguardar la seguridad de los trabajadores, considerando medidas preventivas, orientadas a reducir los riesgos de accidentes y enfermedades profesionales.</p>
                
                <p style="margin-bottom:20px"><strong>Horarios de trabajo:</strong> La empresa ha establecido diversos horarios de trabajo, según los requisitos de producción y conforme a las necesidades de cada planta, respetando los descansos establecidos en la legislación laboral y fomentando la conciliación de la vida familiar y laboral.</p>
                
                <p style="margin-bottom:20px"><strong>Políticas de Capacitación:</strong> FRADEALCO S.A. establece de forma anual las necesidades de capacitación del personal. La formación o capacitación de los trabajadores es una actividad que aporta a la generación de conocimientos que benefician tanto a la empresa como a los trabajadores. Este tipo de prácticas se suma, además, a la satisfacción personal de los trabajadores, brindando oportunidades de desarrollar sus potencialidades en forma profesional.</p>
                
                <p style="margin-bottom:20px"><strong>Madres trabajadoras:</strong> Respetamos y hacemos respetar, como derecho irrenunciable, los plazos que establece la legislación Argentina y el Convenio Colectivo aplicable a nuestra rama de actividad. Establecemos la NO realización de tareas que exijan esfuerzos considerables que signifiquen un riesgo para su salud en relación con la gestación.</p>
                
                <p style="margin-bottom:20px"><strong>Acción Sindical:</strong> Apoyamos la libertad sindical, que promueve la legislación Argentina. Nos regimos por el Convenio Colectivo de INDUSTRIAS QUÍMICAS Y PETROQUÍMICAS DE LA REPÚBLICA ARGENTINA, promovemos y generamos espacios de diálogo con ellos.</p>
                
                <p style="margin-bottom:20px"><strong>Personal Externo:</strong> FRADEALCO S.A. controla que todas las empresas que brindan habitualmente y en forma continua servicio en nuestras dependencias, el personal sea contratado en forma legal y registrada.</p>
                
                <p style="margin-bottom:20px"><strong>Remuneración:</strong> Respetamos el derecho personal a un salario mínimo, no realizamos deducciones al salario por razones disciplinarias, salvo las que por su gravedad o reiteración conlleven suspensión de días u horas de trabajo.</p>
                
                <p style="margin-bottom:20px"><strong>Corrupción y/o soborno:</strong> Nuestra política es clara: Todos los empleados, directivos y miembros que actúen en nombre de FRADEALCO S.A. no pueden pagar sobornos o tratar de influir indebidamente de otra manera en ninguna otra persona – ya sea en el sector público o en el sector privado – incluso si dicho pago se solicita y se niega su condición de soborno.</p>
                
                <p><strong>Mejora Continua:</strong> Llevar a cabo nuestro trabajo dentro de un entorno saludable y seguro en el que se garantice una mejora continua en nuestros procesos, mediante una revisión periódica de nuestros objetivos establecidos en el Sistema de Gestión.</p>'
                styleClass="thumbnail-4"
                // mainThumb="/images/others/header-logistica.jpeg"
                />
                
                {/* <div style={{textAlign:'center'}}>
                    <Link to="/buenas-practicas-manofactura">
                        <Tilty perspective={4000} reset={false}>
                            <img src={process.env.PUBLIC_URL + '/images/others/bpmNewClaypole.png'} alt="Buenas Prácticas de Manufactura" />
                        </Tilty>
                    </Link>
                </div> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default NuestrasPoliticas;