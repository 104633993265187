import React from 'react';
import LocationData from '../../data/contact/LocationData.json';

const allData = LocationData;

const ContactLocation = () => {
    return (
        <>
            <div className="col-lg-6 col-sm-6">
                <div className="office-location">
                    <div className="content">
                        <h4 className="title">Administración</h4>
                        <p>Av. Lacaze 3775, Claypole (1849), Buenos Aires, Argentina</p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2406.396681952523!2d-58.34523196622582!3d-34.80941930244053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32b613d74f8d5%3A0x8cb3effa02c32856!2sFradealco+S.A!5e0!3m2!1ses!2sar!4v1441465127418" width="100%" height="250" ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-6">
                <div className="office-location">
                    <div className="content">
                        <h4 className="title">Destilería</h4>
                        <p>Tomás Guido 1848, Burzaco (1852), Buenos Aires, Argentina</p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.8032326891284!2d-58.408080084788715!3d-34.836039480399926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd491d093cdef%3A0xb134fd7c312be164!2sFRADEALCO%20S.A.!5e0!3m2!1sen!2sar!4v1662402496605!5m2!1sen!2sar" width="100%" height="250"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactLocation;