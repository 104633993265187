import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerFour from "../component/banner/BannerFour";
import DesignSection from "./sections/DesignSection";
import HeroSection from "./sections/HeroSection";
import Quote from "./sections/Quote";
import { GlobalStyle } from "./styles/GlobalStyle";
import ProcessorSection from "./sections/ProcessorSection";
// import BatterySection from "./sections/BatterySection";
import ColorSection from "./sections/ColorSection";
import CameraSection from "./sections/CameraSection";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";


const HomeStartup = () => {
  return (
    <>
      <SEO title="Alcoholes" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerFour />
        <GlobalStyle />
        <Quote />
        <HeroSection />
        <DesignSection />
        <ProcessorSection />
        {/* <BatterySection /> */}
        <ColorSection />
        <CameraSection />
        <FooterOne />
      </main>
    </>
  );
};

export default HomeStartup;
