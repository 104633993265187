import gsap from "gsap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

const Section = styled.section`
  width: 100vw;
  height: 80vh;
  position: relative;
  background-image: url('../images/fondo_home.png');
  background-size: cover;
  color: white !important;
  transition: opacity 1s ease-in-out; /* Transición añadida */
  @media screen and (max-width: 60em) {
    height: 40vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--white);
  overflow: hidden;

  img {
    width: 150px;

    @media screen and (max-width: 70em) {
      width: 50px;
    }
  }

  .hidden {
    opacity: 0;
    transition: opacity .3s ease-in-out; /* Transición añadida */
  }
  .visible1 {
    opacity: 1;
    transition: opacity 1s ease-in-out; /* Transición añadida */
  }
`;


const TextContainer = styled.p`
  width: 100%;
  height: 50vh;
  z-index: 1;

  @media screen and (max-width: 60em) {
    height: 15vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white !important;
  align-items: flex-start;

  span {
    font-size: var(--fontBig);
    width: 90%;
    text-shadow: 0 0 3px #000;
    font-weight: 600;
    text-transform: capitalize;
  }

  @media screen and (max-width: 70em) {
    span {
      font-size: var(--fontxxxl);
    }
  }
  @media screen and (max-width: 64em) {
    span {
      font-size: var(--fontxxl);
    }
  }
  @media screen and (max-width: 48em) {
    span {
      font-size: var(--fontlg);
    }
  }
`;

const TextContainer2 = styled.p`
  width: 100%;
  height: 50vh;

  @media screen and (max-width: 60em) {
    height: 15vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: var(--dark);

  span {
    font-size: var(--fontBig);
    width: 80%;
    color: white !important;
    text-shadow: 0 0 3px #000;
    font-weight: 600;
    text-transform: capitalize;
    align-self: flex-end;
    text-align: right;
  }

  @media screen and (max-width: 70em) {
    span {
      font-size: var(--fontxxl);
    }
  }
  @media screen and (max-width: 64em) {
    span {
      font-size: var(--fontxl);
    }
  }
  @media screen and (max-width: 48em) {
    span {
      font-size: var(--fontlg);
    }
  }
`;

const AdditionalImages = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 2rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }

  img {
    width: 100px;

    @media screen and (max-width: 70em) {
      width: 50px;
    }
  }
`;

const DesignSection = () => {
  const container = useRef(null);
  const textOne = useRef(null);
  const textTwo = useRef(null);
  const [showAdditionalImages, setShowAdditionalImages] = useState(false);

  useLayoutEffect(() => {
    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "top-=500 top",
        end: "bottom top",
        scrub: 1,
      },
    })
    .fromTo(textOne.current, { x: 0 }, { x: "10%" }, "key1")
    .fromTo(textTwo.current, { x: 0 }, { x: "-10%" }, "key1");

    return () => {
      if (t1) t1.kill();
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowAdditionalImages(prevState => !prevState);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Section ref={container}>
      <TextContainer ref={textOne} className={!showAdditionalImages ? "visible1" : "hidden"}>
        <span>
          <img src={`${process.env.PUBLIC_URL}/images/kosher.png`} alt="" /> Certificación Kosher
        </span>
      </TextContainer>

      <TextContainer2 ref={textTwo} className={!showAdditionalImages ? "visible1" : "hidden"}>
        <span>
          <img src={`${process.env.PUBLIC_URL}/images/bpm1.png`} alt="" /> Certificación BPM
        </span>
      </TextContainer2>
      

      <AdditionalImages className={showAdditionalImages ? "visible1" : "hidden"} style={{top:'22%', bottom:'initial'}}>
        <img src={`${process.env.PUBLIC_URL}/images/9001.jpg`} style={{width:"250px",marginRight:'50px'}} alt="Certificación ISO 9001" />
        <img src={`${process.env.PUBLIC_URL}/images/140001.jpg`} style={{width:"250px"}} alt="Certificación ISO 140001" />
      </AdditionalImages>
    </Section>
  );
};

export default DesignSection;
