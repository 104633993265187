import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import AboutFour from '../component/about/AboutFour';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const QuienesSomos = () => {

    return (
        <>
            <SEO title="Quienes somos" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Quienes somos"
                paragraph="<p>Fradealco S.A. es una empresa privada fundada en el año 1977 por su actual presidente, Marcos Julio Fernández. Sus plantas están situadas en las ciudades de Claypole y Burzaco, Partido del Almirante Brown, Buenos Aires. República Argentina.</p>
                <p>En sus comienzos fue concebida con el propósito de fabricar y comercializar alcoholes de uso medicinal e industrial. Actualmente Fradealco S.A, tiene el orgullo de liderar el mercado fraccionamiento de alcoholes de la República Argentina. En los últimos años, nuestro compromiso y responsabilidad con el público consumidor, se ha extendido a través de nuestra línea de vinagres, de alcohol, manzana y vino, y de jugo de limón.</p>
                <p>Junto con la manufactura de productos químicos como el azufre y con la fabricación de envases plásticos se completan las principales actividades industriales y comerciales en las que incursiona Fradealco S.A.</p>
                <p>Nuestro esfuerzo orientado al área de investigación y desarrollo de nuevos productos, el cual contribuye a un crecimiento económico e industria de la Argentina, nos permite mantener una posición altamente competitiva.</p>
                <p>En Fradealco S.A. estamos acostumbrados a dar lo mejor de nosotros mismos, siguiendo bajo toda circunstancia una línea de conducta que prioriza los más altos valores éticos.</p>"
                styleClass="thumbnail-4"
                // mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutFour 
                    title="Refinería de alcohol etílico"
                    subtitle="Tecnología"
                    mainThumb="/images/others/header-refinado-bg.jpeg"
                    paragraph="<p>Fradealco S.A., re-invierte permanentemente en tecnología, buscando incorporar el más alto nivel disponible en el mercado, perfeccionando sus métodos productivos y manteniéndose así en una posición altamente competitiva.</p>
                    <p>Hoy contamos con una moderna refinería de alcohol etílico, la cual nos permite obtener alcoholes extra finos neutros de calidad óptima para las industrias farmacéutica, cosmética, licorista y alimenticia. Así como también alcohol absoluto.</p>"
                />
                <br />
                <AboutFour 
                    title="Almacenamiento de materias primas"
                    right={true}
                    subtitle="Tecnología"
                    mainThumb="/images/others/burzaco A 01.jpg"
                    paragraph="<p>Poseemos tanques de gran capacidad para el Almacenamiento de alcoholes y vinagres, lo que nos permite tener un stock permanente para satisfacer de manera ininterrumpida las necesidades de cada uno de nuestros clientes.</p>"
                />
                <br />
                
                <AboutFour 
                    title="Inyectoras y sopladoras"
                    subtitle="Tecnología"
                    mainThumb="/images/others/image019.jpg"
                    paragraph="<p>Actualmente producimos los envases y tapas para casi la totalidad de nuestros productos, logrando así una alta integración de nuestra cadena productiva, lo cual nos permite mantener una óptima relación calidad-precio en el mercado.</p>"
                />
                <br />
                
                <AboutFour 
                    title="Líneas de envasado"
                    right={true}
                    subtitle="Tecnología"
                    mainThumb="/images/others/header-envasado-bg.jpeg"
                    paragraph="<p>Nuestras modernas líneas de envasado para nuestros diferentes productos nos permiten dar una respuesta rápida a las demandas de nuestros clientes, manteniendo altos volúmenes de producción diaria, obteniendo así una alta productividad y competitividad en nuestros productos.</p>"
                />
                <br />
                <AboutFour 
                    title="Logística y Transporte"
                    right={true}
                    subtitle=""
                    mainThumb=""
                    paragraph="<p>Una de las fortalezas de Fradealco S.A, ha sido su vasto y afianzado sistema de distribución que cubre todo el territorio Argentino y se proyecta paulatinamente hacia la integración con el Mercosur.</p>
                    <p>La logística y distribución se realiza a través de nuestra propia flota de modernos camiones. Asegurando así la entrega de nuestros productos en óptimas condiciones en el momento que el cliente lo crea oportuno.</p>
                    <p>Nuestro alto nivel de profesionalidad en esta area, nos permite desde hace más de 30 años complacer las más altas exigencias de nuestros clientes.</p>"
                />


<br />
                <AboutFour 
                    subtitle="Tecnología en nuestro transporte"
                    mainThumb="/images/others/header-transporte-bg3.jpg"
                    paragraph="<p>Contamos con una amplia flota de camiones Cisterna de acero inoxidable y aluminio, chasis y semis para garantizar tanto en tiempo y forma de entrega de nuestros productos, así como la no contaminación y estado óptimo de los mismos al llegar a nuestros clientes. Dicha flota nos permite abastecer a los más importantes supermercados, mayoristas, laboratorios e industrias de nuestro país.</p>"
                />

                {/* <AboutFour /> */}
                {/* <AboutThree />
                <AboutFive />
                <ProcessOne />
                <CtaLayoutOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default QuienesSomos;